import "./styles.scss"

import React from "react"

import Tile from "./components/Tile"

const Distance = () => {
  return (
    <section className="location-distance">
      <div className="container-fluid">
        <h3>
          Powiedz "TAK" dla miasta,
          <br />
          zamieszkaj na Łobzowie
        </h3>
        <div className="row location-distance__row">
          <div className="col-md-4">
            <Tile
              image={
                require("assets/images/location/location-distance-image-1.jpg")
                  .default
              }
              title="Rynek główny"
              time="10 minut"
            />
          </div>
          <div className="col-md-4">
            <Tile
              image={
                require("assets/images/location/location-distance-image-2.jpg")
                  .default
              }
              title="Park Jordana"
              time="7 minut"
            />
          </div>
          <div className="col-md-4">
            <Tile
              image={
                require("assets/images/location/location-distance-image-3.jpg")
                  .default
              }
              title="Bielańsko-Tyniecki Park Krajobrazowy"
              time="8 minut"
            />
          </div>
          <div className="col-md-8">
            <Tile
              image={
                require("assets/images/location/location-distance-image-4.jpg")
                  .default
              }
              lists={[
                [
                  {
                    title: "Politechnika Krakowska",
                    distance: "1",
                  },
                  {
                    title: "Uniwersytet Pedagogiczny",
                    distance: "1",
                  },
                  {
                    title: "Uniwersytet Ekonomiczny",
                    distance: "2",
                  },
                ],
                [
                  {
                    title: "Uniwersytet Rolniczy",
                    distance: "2,5",
                  },
                  {
                    title: "Akademia Górniczo-Hutnicza",
                    distance: "2,5",
                  },
                ],
              ]}
            />
          </div>
          <div className="col-md-4">
            <Tile
              image={
                require("assets/images/location/location-distance-image-5.jpg")
                  .default
              }
              lists={[
                [
                  {
                    title: "Galeria Bronowice",
                    distance: "2,5",
                  },
                  {
                    title: "IKEA",
                    distance: "2,5",
                  },
                ],
              ]}
              hasShape
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Distance
