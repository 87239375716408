import "./styles.scss"

import React from "react"

const Tile = ({ title, image, time, lists, hasShape }) => {
  return (
    <div className="distance-tile">
      <div className="distance-tile__image">
        <div
          className="distance-tile__image-inner"
          style={{ backgroundImage: `url(${image})` }}
        />
        {hasShape && <div className="distance-tile__image-shape" />}
      </div>
      {lists ? (
        <div className="distance-tile__list">
          <div className="row">
            {lists.map((list, index) => (
              <div key={index} className={`col-md-${12 / lists.length}`}>
                {list.map((item, index) => (
                  <div className="distance-tile__list-item" key={index}>
                    <h5>{item.title}</h5>
                    <p>{item.distance} km</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="distance-tile__header">
          {title && <h4>{title}</h4>}
          {time && (
            <div className="distance-tile__time">
              <div className="distance-tile__time-icon">
                <img
                  src={
                    require("assets/images/location/bicycle-ico.svg").default
                  }
                  alt=""
                />
              </div>
              <div className="distance-tile__time-text">{time}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Tile
