import "./styles.scss"

import React from "react"

const Questions = () => {
  const data = [
    {
      question: "Supermarkety, sklepy, urzędy, ośrodki zdrowia?",
      answer: "W bezpośredniej okolicy inwestycji!",
    },
    {
      question: "Restauracje, puby, kina teatry?",
      answer: "Po sąsiedzku na Starym Mieście!",
    },
    {
      question: "Komunikacja miejska?",
      answer: "Na miejscu!",
    },
  ]

  return (
    <section className="location-questions">
      <div className="container-fluid">
        <div className="row">
          {data?.map((item, index) => (
            <div key={index} className="col-lg-4">
              <p>{item.question}</p>
              <h4>{item.answer}</h4>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Questions
