import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Content from "components/Content"

import { Map, Distance, Questions } from "page_components/location"

const Location = () => {
  const title = "Lokalizacja"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Map />
      <Distance />
      <Questions />
      <Content
        image={
          require("assets/images/location/location-features-image-1.jpg")
            .default
        }
        hasShape
      >
        <h2>Blisko miasta, blisko świata</h2>
        <h3>MPK, PKP i Lotnisko w Balicach</h3>
        <p>
          Mieszkając w City Łobzów w 10 minut znajdziesz się na stacji PKP
          Łobzów. Z kolei w ciągu 15 minut dostaniesz się do dworca PKP i PKS,
          również w kwadrans dotrzesz na lotnisko Kraków Balice. Miasto, Polska
          i świat masz na wyciągnięcie ręki.
        </p>
        <ul>
          <li>
            <strong>700 m / 10 minut pieszo</strong>
            <br />
            do przystanku PKP "Kraków Łobzów" i PKS "Łobzów SKA"
          </li>
          <li>
            <strong>
              2,5 km / 10 minut samochodem /<br />
              15 minut komunikacją miejską
            </strong>
            <br />
            do dworca głównego PKP i PKS
          </li>
          <li>
            <strong>12 km / 15 minut samochodem / 15 minut PKP</strong>
            <br />
            do międzynarodowego lotniska Kraków-Balice
          </li>
        </ul>
      </Content>
      <Content
        image={
          require("assets/images/location/location-features-image-2.jpg")
            .default
        }
        isReverse
        isLast
      >
        <h2>Łatwy dojazd do najważniejszych punktów na mapie Krakowa</h2>
        <p>
          Mieszkanie w tej części Krakowa to gwarancja łatwego dotarcia do
          najbardziej popularnych miejsc na mapie miasta. To idealna lokalizacja
          zarówno dla osób nastawionych na biznes, rodzin z dziećmi, a także dla
          studentów.
        </p>
      </Content>
    </Layout>
  )
}

export default Location
