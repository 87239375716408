import "./styles.scss"

import React from "react"
import clsx from "clsx"

const Content = ({
  type: Type = "section",
  children,
  image,
  isReverse,
  isLast,
  hasShape,
}) => {
  const isImageArray = Array.isArray(image)
  const hasMoreImages = isImageArray && image.length > 1

  return (
    <Type
      className={clsx(
        "content",
        isReverse && "content--reverse",
        isLast && "content--last",
        hasMoreImages && "content--more-images"
      )}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            {image && (
              <div
                className={clsx(
                  "content__image",
                  hasShape && "content__image--shape"
                )}
              >
                <img src={isImageArray ? image[0] : image} alt="" />
                {hasMoreImages && (
                  <div className="content__image-floating">
                    <img src={image[1]} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="col-lg-6">
            <div className="content__description">{children}</div>
          </div>
        </div>
      </div>
    </Type>
  )
}

export default Content
